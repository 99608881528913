 .bookPaper {
  padding: 15px;
  border: 1px solid #ccc;
  margin-top: 10px;
  background-color: #ffffff;
}
.mobile{
    overflow-y: auto;
}
.tabButtons , .tabContent {
  margin-right: 5px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  overflow-y: scroll;

}

.tabButtons .active-tab {
  font-weight: bold;
  color:  #3a65ff;
  border: none;
}
.tabButton{
    margin-left: 20px;
}
#booksButtonn{
    background: rgb(58, 101, 255);
    color: #ffffff;
    padding: 5px 10px;

    border-radius: 5px 0px 0px 5px;
    box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0) !important;
    border: none;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
     width: 80px !important;
  }

.tabButtons{
  cursor: pointer;
}


@media (min-width:375px) and (max-width:480px){

  #booksButtonn{
    background: rgb(58, 101, 255);
    color: rgb(255, 255, 255);
    padding: 5px 8px;
box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0) !important;
    border-radius: 5px 0px 0px 5px;
    border: none;
    text-transform: uppercase;
    font-size: 10px; 
   width: 48px !important;

  }
   #chaptBtnMobilee{
  background-color: #3a65ff;
  color:#fff;
  padding: 2px ;
  border-radius: 0px 5px 5px 0px; 
 width: 18px !important; 
   height: 21.2px !important;
  min-width: 18px !important;
  min-height: 21.2px !important;
  max-width: 18px !important; 
 max-height: 21.2px !important; 
  margin-left:1.5px !important;
align-items: center; 
border: none;
cursor: pointer;
margin-top: 1.6px;
}
}
  

 @media (min-width:319px) and (max-width:374px){

  #booksButtonn{
    background: rgb(58, 101, 255);
    color: rgb(255, 255, 255);
    padding: 4px 5px !important;
box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0) !important;
border-radius: 5px 0px 0px 5px;
    border: none;
    text-transform: uppercase;
    font-size:9px !important; 
   width: 38px !important;
   max-width: 38px !important;
   min-width: 40px !important;
 }
    #chaptBtnMobilee{
  background-color: #3a65ff;
  color:#fff;
  padding: 2px ;
  border-radius: 0px 5px 5px 0px; 
 width: 17px !important; 
   height: 18.5px !important; 
  min-width: 17px !important;
  min-height: 18.5px !important; 
  max-width: 17px !important; 
 max-height: 18.5px !important; 
  margin-left:1.5px !important;
  margin-top: 3.4px;
align-items: center; 
border: none;
cursor: pointer;}
} 

@media(min-width:630px) and (max-width:849px){
       #booksButtonn{
       background: rgb(58, 101, 255);
       color: rgb(255, 255, 255);
       padding: 8px 16px;
box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0) !important;
border-radius: 5px 0px 0px 5px;
      border: none;
      text-transform: uppercase;
       font-size: 15px;
    }
    #chaptBtnMobilee{
  background-color: #3a65ff;
  color:#fff;
  padding: 2px ;
  border-radius: 0px 5px 5px 0px; 
 width: 30px; 
   height: 32.9px !important;
  min-width: 30px;
  min-height: 32.9px !important ;
  max-width: 30px; 
 max-height:32.9px !important;
  margin-left:-2.5px;
 
align-items: center; 
border: none;
cursor: pointer;

}
}

  @media (min-width: 850px) and (max-width: 1200px) {
       #booksButtonn{
       background: rgb(58, 101, 255);
       color: rgb(255, 255, 255);
       border-radius: 5px 0px 0px 5px !important;
       padding: 8px 16px;
       border-radius: 5px;
      border: none;
      text-transform: uppercase;
      padding: 5px 10px;
      font-size: 15px;
    }
    #chaptBtnMobilee{
  background-color: #3a65ff;
  color:#fff;
  padding: 2px ;
  border-radius: 0px 5px 5px 0px; 
 width: 30px; 
   height: 26.5px;
  min-width: 30px;
  min-height: 26.5px;
  max-width: 30px; 
 max-height:26.5px;
  margin-left:-2.5px;
 
align-items: center; 
border: none;
cursor: pointer;

}
}


#chaptBtnMobilee{
      background-color: #3a65ff;
  color:#fff;
   padding: 3px ;
box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0) !important;
  border-radius: 0px 5px 5px 0px; 
 width: 30px; 
   height: 27px;
  min-width: 30px;
  min-height: 27px;
  max-width: 30px; 
 max-height:27px; 
margin-left:-2.5px;
align-items: center; 
border: none;
cursor: pointer;
padding:fit-content;
}
#chaptBtnMobilee:hover{
background-color: rgb(3,27,114);
color: #ffffff;
}

 @media(min-width:481px) and (max-width:629px){
 #booksButtonn{
       background: rgb(58, 101, 255);
       color: rgb(255, 255, 255);
       padding: 5px 7px;
     box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0), 0px 3px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0) !important;
     border-radius: 5px 0px 0px 5px;
     
      border: none;
      text-transform: uppercase;
       font-size: 13px;
       width: 57px !important;
    }
    #chaptBtnMobilee{
  background-color: #3a65ff;
  color:#fff;
  padding: 2px ;
  border-radius: 0px 5px 5px 0px; 
 width: 25px; 
   height: 25.1px !important;
  min-width: 25px;
  min-height: 25.1px !important ;
  max-width: 25px; 
 max-height:25.1px !important;
  margin-left:-2.5px;
 
align-items: center; 
border: none;
cursor: pointer;
 }

 }

.bible-page-options {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid rgb(177, 177, 177);
  box-shadow: rgb(3 27 78 / 10%) 0px 2px 4px;
  background-color: #f2f5ff;
}
:root {
    --bright-blue: #3a65ff;
}
:root{
  --deep-blue:rgb(3,27,114);
}
.bible-page-options .jss1 {
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: auto;
  position: relative !important;
  border: none !important;
  pointer-events: auto;
}

.bible-index {
  display: flex;
  margin: 5px 0;
}

.bible-index-item {
  margin: 5px;
}

.options-toggles {
  display: flex;
}

.options-toggles button {
  margin: 5px;
  padding:fit-content !important;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background:none;
  font-weight: 500;
  font-size: 13.3333px;
  text-transform: uppercase;
  height: 30px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid var(--bright-blue);
  color:var(--bright-blue);
  height: 30px;
}

.options-toggles button:hover,
.options-toggles button:active {
  background-color:var(--bright-blue);
  color: white;
}

.options-toggles button.active {
  background-color:var(--bright-blue);
  color: rgb(255, 255, 255);
}

.primary-toggles,
.secondary-toggles {
  display: flex;
    align-items: center;
  flex-wrap: wrap;
}

.bible-page-options .close-button {
  font-size: 1.5em;
  cursor: pointer;
  height: 35px;
  width: 35px;
  float: right;
  text-align: center;
}

.bible-page-options .close-button:active,
.bible-page-options .close-button:hover {
  background-color: #869bff;
}

.bookButton {
  border: none;
  outline: none;
  background: transparent;
  margin: 5px auto;
  font-size: 14px;
  width: 200px;
  text-align: left;
  cursor: pointer;
  justify-content: flex-start;
  text-transform: initial;
}
.book-button {
  border: none;
  outline: none;
  width: 35px;
  background: transparent;
  margin: 5px 0;
  cursor: pointer;
}
.bookAlign {
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  align-content: flex-start;
  width: 100%;
  height: 265px;
}
.NTbookAlign {
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  align-content: flex-start;
  width: 100%;
  height: 225px;
}
.bookPaper {
  width: 1045px;
  padding: 10px;
}
.css-19kzrtu {
  padding: 3px !important;
}
.old_new {
  margin: 5px;
  font-weight: 600;
  text-decoration: underline;
}
.mobile {
  display: none;
}
.lastBookButton {
  background: transparent;
  border: 1px solid var(--bright-blue);
  font-size: 12px;
  padding: 5px 0px;
  height: min-content;
  border-radius: 5px;
  
  min-width: 80px;
  width: 80px;
  cursor: pointer;
   
}


.lastBookButton:hover{
  background-color: var(--deep-blue);
  color: #ffffff;
  border: 1px solid var(--deep-blue);
}

@media (max-width: 900px) {
  .notMobile {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .NTbookAlign {
    height: 430px !important;
  }
  .bookButton {
    width: 162px !important;
    font-size: 13px !important;
  }
  .bookPaper {
    width: 340px !important;
  }
  .bible-index {
    justify-content: space-between;
  }

  .options-toggles {
    flex-flow: column;
    width: 100%;
  }

  .primary-toggles button,
  .secondary-toggles button,
  .bible-index-item {
    flex: 1 1 auto;
  }
   .secondary-toggles {
    display: block;
  }
}

@media (max-width: 1200px) {
  .bible-page-options {
    flex-flow: column;
    position: sticky;
    top: 0;
  }

  .bible-index {
    justify-content: space-between;
  }

  .options-toggles {
    flex-flow: column;
    width: 100%;
  }

  .primary-toggles button,
  .secondary-toggles button,
  .bible-index-item {
    flex: 1 1 auto;
  }

  .secondary-toggles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 50px !important;
  height: 50px !important;
  font-weight: 900;
  background: rgba(0, 123, 255, 0.514);
  border-radius: 100%;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
:root {
  --swiper-navigation-size: 30px !important;
}

.lastFifteenClearButton {
  background: transparent;
  border: 1px solid red;
  padding: 5px;
  border-radius: 5px;
}

.select-versions input{
  cursor: pointer;
}
.custom-dialog {
  width: 90% !important; 
  max-width: 60vw !important; 
  height: auto !important; 
  margin: auto !important; 
  border-radius: 8px;
  box-sizing: border-box; 
}

@media (min-width: 600px) {
  .custom-dialog {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .custom-dialog {
    width: 40% !important; 
  }
}
#chaptBtnMobile{
   background-color:var(--bright-blue);
  color:#fff;
   padding: 3px ;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  width: 40px; 
   height: 30px;
  min-width: 40px;
  min-height: 30px;
  max-width: 40px; 
 max-height: 30px; 
  margin-left:-2.5px;
align-items: center; 
border: none;
cursor: pointer;
}
#chaptBtnMobile:hover{
background-color: var(--deep-blue);
color: #ffffff;
}
.bookchapter .chapButtn{
   background-color:var(--bright-blue);
  color:#fff;
   padding: 0px !important;
   border-radius: 0px 5px 5px 0px; 
 width: 30px; 
   height: 30px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px; 
 max-height: 30px; 
 margin-left: 2.5px;
 align-items: center;
}
.booksButtn{
  background: var(--bright-blue);
  color: #fff;
  padding: 3px 10px;
  box-shadow: none;
}
.boxShadow{
   box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
}
.arrowupdown:hover{
background-color: var(--deep-blue);
color: #ffffff;
}